import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';

@Injectable({
  providedIn: 'root',
})
export class StorageAdapter {
  constructor() {}

  public async set(key: string, value: any): Promise<void> {
    await Preferences.set({
      key,
      value,
    });
  }

  public async get(key: string): Promise<any> {
    const { value } = await Preferences.get({ key });
    return value;
  }

  public async remove(key: string): Promise<void> {
    await Preferences.remove({ key });
  }

  public async clear(): Promise<void> {
    await Preferences.clear();
  }
}
