import { Injectable } from '@angular/core';
import BackgroundGeolocation from '@transistorsoft/capacitor-background-geolocation';
@Injectable({
  providedIn: 'root',
})
export class AuthorizationEventService {
  public constructor() {}

  public static addEvent() {
    BackgroundGeolocation.onAuthorization((authorizationEvent) => {
      if (authorizationEvent.success) {
        console.log(
          '[DEBUG] [authorization] SUCCESS: ',
          authorizationEvent.response
        );
      } else {
        console.log(
          '[DEBUG] [authorization] FAILURE: ',
          authorizationEvent.error
        );
      }
    });
  }
}
