import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class AlertToastController extends ToastController {
  public async info(message: string): Promise<void> {
    await this.present('Information', message, 'blue');
  }

  public async success(message: string): Promise<void> {
    await this.present('Success', message, 'success');
  }

  public async error(message: string): Promise<void> {
    await this.present('Error', message, 'danger');
  }

  public async warning(message: string): Promise<void> {
    await this.present('Warning', message, 'warning');
  }

  private async present(header: string, message: string, color: string) {
    const toast = await this.create({
      header,
      message,
      position: 'bottom',
      color,
      duration: 2000,
    });
    await toast.present();

    const { role } = await toast.onDidDismiss();
  }
}
