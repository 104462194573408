import { NgModule } from '@angular/core';
import { PreloadAllModules } from '@angular/router';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./tabs/tabs.module').then((m) => m.TabsPageModule),
  },

  /* auth router */
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'apple/callback',
    loadChildren: () =>
      import('./pages/callback/callback.module').then(
        (m) => m.CallbackPageModule
      ),
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./pages/register/register.module').then(
        (m) => m.RegisterPageModule
      ),
  },
  {
    path: 'confirm-email',
    loadChildren: () =>
      import('./pages/confirm/confirm.module').then((m) => m.ConfirmPageModule),
  },
  {
    path: 'password-reminder',
    loadChildren: () =>
      import('./pages/password-reminder/password-reminder.module').then(
        (m) => m.PasswordReminderPageModule
      ),
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('./pages/password-reset/password-reset.module').then(
        (m) => m.PasswordResetPageModule
      ),
  },
  {
    path: 'success',
    loadChildren: () =>
      import('./pages/register-success/register-success.module').then(
        (m) => m.RegisterSuccessPageModule
      ),
  },
  {
    path: 'privacy',
    loadChildren: () =>
      import('./pages/privacy/privacy.module').then((m) => m.PrivacyPageModule),
  },
  {
    path: 'support',
    loadChildren: () =>
      import('./pages/support/support.module').then((m) => m.SupportPageModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      enableTracing: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
