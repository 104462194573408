import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoginRequest, LoginResponse } from '../dtos/login';
import { TokenRequest, TokenResponse } from '../dtos/token';
import { ResponseHandler } from 'src/app/utils/response-handler.utils';

const API = `${environment.apiUrl}Authentication`;

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(private readonly http: HttpClient) {}

  public login(request: LoginRequest): Observable<LoginResponse> {
    return this.http.post(`${API}`, request).pipe(
      map((response: any) => {
        if (ResponseHandler.handle(response)) {
          return response.auth;
        }
      })
    );
  }

  public async refreshToken(request: TokenRequest): Promise<TokenResponse> {
    const credentials = JSON.stringify(request);

    return await new Promise<any>((resolve, reject) => {
      this.http
        .post<any>(`${environment.apiUrl}token/refresh`, credentials)
        .pipe(
          map((response: any) => {
            resolve(response);
          })
        )
        .subscribe({
          error: (err) => {
            reject(err);
          },
        });
    });
  }
}
