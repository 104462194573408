import { Component, Optional } from '@angular/core';
import { IonRouterOutlet, Platform } from '@ionic/angular';
import { App } from '@capacitor/app';
import { tap } from 'rxjs';
import { GeolocationService } from './adapter/transistorsoft/services/geolocation.service';
import { ErrorService } from './logging/services/error.service';
import { AlertToastController } from './adapter/ionic/alert-toast-controller';
import { CurrentUserService } from './services/current-user.service';
import { BackgroundGeolocationConfig } from './adapter/transistorsoft/interfaces/background-geolocation-config';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  protected opened = true;
  protected isAuthenticated = false;

  constructor(
    private readonly errorService: ErrorService,
    private readonly notify: AlertToastController,
    private readonly platform: Platform,
    private readonly session: CurrentUserService,
    private readonly geolocationService: GeolocationService,
    @Optional() private routerOutlet?: IonRouterOutlet
  ) {
    this.platform.backButton.subscribeWithPriority(-1, () => {
      if (this.routerOutlet && !this.routerOutlet.canGoBack()) {
        alert('Back button');
        App.exitApp();
      }
      this.platform.backButton.subscribeWithPriority(5, () => {
        console.log('[DEBUG] Another handler was called!');
      });

      this.platform.backButton.subscribeWithPriority(
        10,
        (processNextHandler) => {
          console.log('[DEBUG] Handler was called!');

          processNextHandler();
        }
      );
    });

    this.session.tokenObserver
      .pipe(
        tap(async (token: string | null) => {
          if (!token) {
            this.isAuthenticated = false;
          } else {
            this.isAuthenticated = await this.session.isLoggedIn();

            if (this.isAuthenticated) {
              const userId = await this.session.getUserId();
              if (userId) {
                const config = new BackgroundGeolocationConfig();
                config.debug = false;
                config.distanceFilter = 10;
                config.stationaryRadius = 5;
                config.stopOnTerminate = false;
                config.useSignificantChangesOnly = false;
                await this.geolocationService.configureBackgroundGeolocation(
                  userId,
                  config
                );
              }
            }
          }
        })
      )
      .subscribe({
        error: (errResponse: any) => {
          const msg = this.errorService.handle(errResponse);
          this.notify.error(msg);
        },
      });
  }

  public collapse() {
    this.opened = false;
  }

  public toggle() {
    this.opened = !this.opened;
  }
}
