import { Injectable } from '@angular/core';
import BackgroundGeolocation from '@transistorsoft/capacitor-background-geolocation';
@Injectable({
  providedIn: 'root',
})
export class HttpEventService {
  public constructor() {}

  public static addEvent() {
    BackgroundGeolocation.onHttp((response) => {
      console.log(
        '[DEBUG]  [http] response for plugin: ',
        response.success,
        response.status,
        response.responseText
      );
    });
  }
}
