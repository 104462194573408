import { Injectable } from '@angular/core';
import { CurrentUserService } from 'src/app/services/current-user.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  constructor(
    private readonly session: CurrentUserService,
    private readonly authenticationService: AuthenticationService
  ) {}

  public async refreshingTokens(accessToken: string | null): Promise<boolean> {
    const refreshToken: string | null = await this.session.getRefresherToken();
    if (!accessToken || !refreshToken) {
      return false;
    }

    let isRefreshSuccess = false;

    try {
      const refreshRes = await this.authenticationService.refreshToken({
        accessToken,
        refreshToken,
      });
      const newToken = refreshRes.accessToken;
      const newRefreshToken = refreshRes.refreshToken;

      await this.session.setRefresherToken(newRefreshToken);
      await this.session.setToken(newToken);

      isRefreshSuccess = true;
    } catch (ex) {
      isRefreshSuccess = false;
    }
    return isRefreshSuccess;
  }
}
