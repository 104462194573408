import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import BackgroundGeolocation from '@transistorsoft/capacitor-background-geolocation';
import { CurrentUserService } from 'src/app/services/current-user.service';
import { environment } from 'src/environments/environment';
import { AuthorizationEventService } from '../events/authorization-event.service';
import { HeartbeatEventService } from '../events/heartbeat-event.service';
import { HttpEventService } from '../events/http-event.service';
import { BackgroundGeolocationConfig } from '../interfaces/background-geolocation-config';

@Injectable({
  providedIn: 'root',
})
export class GeolocationService {
  constructor(
    private readonly session: CurrentUserService,
    private readonly platform: Platform
  ) {}

  public async configureBackgroundGeolocation(
    userId: string,
    config: BackgroundGeolocationConfig
  ) {
    if (!(await this.isMobile())) {
      console.log('[DEBUG] Not a mobile application!');
      return;
    }
    const accessToken = await this.session.getToken();
    const refreshToken = await this.session.getRefresherToken();

    AuthorizationEventService.addEvent();
    HeartbeatEventService.addEvent();
    HttpEventService.addEvent();

    // Step 2:  Configure the plugin
    BackgroundGeolocation.ready({
      /* Geolocation Options - Common */
      desiredAccuracy: BackgroundGeolocation.DESIRED_ACCURACY_HIGH,
      distanceFilter: config.distanceFilter,
      stopOnStationary: false /* THIS IS DEFAULT */,
      useSignificantChangesOnly: false /* TRUE  IS DEFAULT */,

      /* iOS Options */
      stationaryRadius: config.stationaryRadius,
      locationAuthorizationAlert: {
        titleWhenNotEnabled: 'Your location-services not enabled',
        titleWhenOff: 'Your location-services OFF',
        instructions:
          'You must enable Always in location-services for when your phone' +
          'is off and you still want to be followed by your friends.',
        cancelButton: 'Cancel',
        settingsButton: 'Settings',
      },
      showsBackgroundLocationIndicator: true,

      /* Android Options */
      allowIdenticalLocations: false /* THIS IS THE DEFAULT */,

      /* Activity Recognition Options */
      stopTimeout: 5 /* THIS IS THE DEFAULT */,

      /*HTTP & Persistence Options */
      url: `${environment.apiUrl}position`,
      params: {
        userId,
      },
      headers: {
        // eslint-disable-next-line @typescript-eslint/quotes, @typescript-eslint/naming-convention
        'Content-Type': 'application/json; charset=UTF-8',
        // eslint-disable-next-line @typescript-eslint/quotes, @typescript-eslint/naming-convention
        'Access-Control-Allow-Origin': '*',
      },
      httpRootProperty: '.',
      // eslint-disable-next-line max-len
      locationTemplate: `{
          "latitude":<%= latitude %>,
          "longitude":<%= longitude %>,
          "speed":<%= speed %>,
          "heading": <%= heading %>,
          "altitude":<%= altitude %>,
          "timeStamp": "<%= timestamp %>",
          "uuid": "<%= uuid %>",
          "odometer":<%= odometer %>,
          "activityType":"<%= activity.type %>",
          "batteryLevel":<%= battery.level %>,
          "isMoving":<%= is_moving %>
        }`,
      maxDaysToPersist: 7,
      locationsOrderDirection: 'DESC',

      /* Application Options */
      stopOnTerminate: config.stopOnTerminate,
      startOnBoot: true,
      heartbeatInterval: 60 /* THIS IS DEFAULT */,

      /* Application iOS */
      preventSuspend: true,

      /* Application Android */
      enableHeadless: true,
      notification: {
        title: 'Share my Adventures',
        text: 'Background tracking engaged',
      },

      /* Logging & Debug Options */
      debug: config.debug,
      logLevel: BackgroundGeolocation.LOG_LEVEL_VERBOSE,

      backgroundPermissionRationale: {
        title:
          'Allow Share my Adventures to access this devices location even when closed or not in use.',
        message:
          'This app collects location data to enable recording your trips and to share ' +
          'it with your family and friends even when the phone is off (only when requested by you)',
        positiveAction: 'Change to Allow all the time',
      },

      /* Properties */
      authorization: {
        strategy: 'JWT',
        accessToken: accessToken === null ? '' : accessToken,
        refreshUrl: `${environment.apiUrl}Token/Refresh`,
        refreshToken: refreshToken === null ? '' : refreshToken,
        refreshPayload: {
          accessToken: '{accessToken}',
          refreshToken: '{refreshToken}',
        },
        refreshHeaders: {
          // eslint-disable-next-line @typescript-eslint/quotes, @typescript-eslint/naming-convention
          'Content-Type': 'application/json; charset=UTF-8',
          // eslint-disable-next-line @typescript-eslint/quotes, @typescript-eslint/naming-convention
          'Access-Control-Allow-Origin': '*',
        },
        expires: 7200,
      },
      reset: true /* THIS IS DEFAULT */,
      isMoving: true,
    }).then(async (state) => {
      console.log(
        '[DEBUG] [ready] BackgroundGeolocation is configured and ready to use'
      );
    });
  }

  /**
   *
   * @param enabled
   */

  public async record(enabled: boolean): Promise<void> {
    if (!(await this.isMobile())) {
      alert('Not mobile');
      console.log('[DEBUG]  Not a mobile platform so cannot configure plugin');
      return;
    }

    if (enabled) {
      BackgroundGeolocation.start();
    } else {
      await BackgroundGeolocation.stop();
    }
  }

  /**
   *
   * @returns
   */
  public async getOdometer(): Promise<string> {
    if (!(await this.isMobile())) {
      console.log('[DEBUG] Not a mobile platform so cannot configure plugin');
      return '0';
    }

    const odo = await BackgroundGeolocation.getOdometer();

    return `${odo / 1000} km`;
  }

  /**
   *
   * @returns
   */
  public async resetOdo() {
    if (!(await this.isMobile())) {
      console.log('[DEBUG] Not a mobile platform so cannot configure plugin');
      return;
    }

    BackgroundGeolocation.resetOdometer().then((location) => {
      // This is the location where odometer was set at.
      console.log('[DEBUG] [setOdometer] success: ', location);
    });
  }

  /**
   *
   * @returns
   */
  public async getCurrentLocation(): Promise<any> {
    if (!(await this.isMobile())) {
      alert('not mobile!');
      console.log('[DEBUG]  Not a mobile platform so cannot configure plugin');
      return;
    }

    return BackgroundGeolocation.getCurrentPosition({});
  }

  private async isMobile(): Promise<boolean> {
    const plt = await this.platform.ready();
    const isDesktop =
      this.platform.is('mobileweb') ||
      this.platform.is('pwa') ||
      this.platform.is('desktop');
    return !isDesktop;
  }
}
