import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, from, lastValueFrom, Observable, throwError } from 'rxjs';
import { TokenService } from '../adapter/api/services/token.service';
import { CurrentUserService } from '../services/current-user.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  isRefreshingToken = false;
  constructor(
    private readonly session: CurrentUserService,
    private readonly tokenService: TokenService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next
      .handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          from(this.handleError(request, error, next))
        )
      );
  }
  async handleError(
    request: HttpRequest<unknown>,
    error: HttpErrorResponse,
    next: HttpHandler
  ): Promise<any> {
    const token: string | null = await this.session.getToken();
    const refresherToken: string | null =
      await this.session.getRefresherToken();
    const isLoggedIn: boolean = await this.session.isLoggedIn();

    if (
      error.status === 401 &&
      token !== null &&
      refresherToken !== null &&
      !isLoggedIn &&
      !this.isRefreshingToken
    ) {
      this.isRefreshingToken = true;

      const didRefresh = await this.tokenService.refreshingTokens(token);
      console.log('[DEBUG] need to refresh', didRefresh);
      return lastValueFrom(next.handle(request));
    } else if (
      error.status === 400 &&
      error.error.errorCode === 'invalid_grant'
    ) {
      this.session.logout();
    } else {
      return lastValueFrom(throwError(() => error));
    }
  }
}
