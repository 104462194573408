import { HttpErrorResponse } from '@angular/common/http';

export class ResponseHandler {
  public static handle(response: BaseResponse): boolean {
    if (response.succeeded) return true;
    throw new HttpErrorResponse({ error: response.errors.join('\n') });
  }
}

export interface BaseResponse {
  succeeded: boolean;
  errors: string[];
}
