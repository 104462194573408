import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { from, lastValueFrom, Observable } from 'rxjs';
import { CurrentUserService } from '../services/current-user.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private session: CurrentUserService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (!request.headers.has('Content-Type')) {
      request = request.clone({
        headers: request.headers
          .set('Content-Type', 'application/json; charset=UTF-8')
          .append('Access-Control-Allow-Origin', '*'),
      });
    }

    return from(this.getToken(request, next));
  }

  private async getToken(request: HttpRequest<unknown>, next: HttpHandler) {
    const token: string | null = await this.session.getToken();

    if (token) {
      request = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + token),
      });
    }
    return lastValueFrom(next.handle(request));
  }
}
