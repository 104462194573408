import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoggingService } from './logging.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor(private readonly logger: LoggingService) {}

  public handle(error: any): string {
    let msg = '';

    if (error instanceof Error) {
      msg = this.getClientMessage(error);
      const stack = this.getClientStack(error);

      if (stack) {
        this.logger.logError(msg, stack);
      }
    } else if (error instanceof HttpErrorResponse) {
      msg = this.getServerMessage(error);

      this.logger.logError(msg);
    }

    return msg;
  }

  private getClientMessage(error: Error): string {
    if (!navigator.onLine) {
      return 'No Internet Connection';
    }
    return error.message ? error.message : error.toString();
  }

  private getClientStack(error: Error): string | undefined {
    return error.stack;
  }

  private getServerMessage(error: HttpErrorResponse): string {
    console.log('[DEBUG] Get Server Message', error);

    if (error.error) {
      return error.error;
    }

    if (error.statusText) {
      return error.statusText;
    } else return 'Unknown error';
  }
}
