import { Injectable } from '@angular/core';
import BackgroundGeolocation from '@transistorsoft/capacitor-background-geolocation';
@Injectable({
  providedIn: 'root',
})
export class HeartbeatEventService {
  public constructor() {}

  public static addEvent() {
    BackgroundGeolocation.onHeartbeat((event) => {
      console.log('[DEBUG] [onHeartbeat] ', event);
    });
  }
}
