const mapKey = 'AIzaSyDEDnsAeIycshJd8dC5Fukc1zv4eU5yT7k';

export const environment = {
  production: false,
  //apiUrl: 'https://localhost:5001/api/',
  apiUrl: 'https://api.sharemyadventures.co.za/api/',
  mapKey,
  pollInterval: 60000, // config for user
  //webUrl: 'http://localhost:8100',
  webUrl: 'https://sharemyadventures.co.za',
  // Your web app's Firebase configuration
  firebaseConfig: {
    apiKey: 'AIzaSyBn3qrfzImJXx0E7SP5lqkQoYPFcMZWosM',
    authDomain: 'share-my-adventures.firebaseapp.com',
    projectId: 'share-my-adventures',
    storageBucket: 'share-my-adventures.appspot.com',
    messagingSenderId: '131692082116',
    appId: '1:131692082116:web:e0764af2f8933825c22a91',
  },
};
