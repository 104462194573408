import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable } from 'rxjs';
import { StorageAdapter } from 'src/app/adapter/ionic/storage';

/**
 * Constant values used for sessions
 */
const TOKEN = 'sma-jwt-token';
const REFRESH = 'sma-jwt-refresh-token';
const USER = 'sma-user-id';

/**
 * @description
 *
 * Service for Current User
 *
 */

@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {
  #tokenSubject: BehaviorSubject<string | null>;
  #tokenObserver: Observable<string | null>;

  constructor(
    private readonly storage: StorageAdapter,
    private readonly jwtHelper: JwtHelperService,
    private readonly router: Router
  ) {
    this.#tokenSubject = new BehaviorSubject<string | null>(null);
    this.#tokenObserver = this.#tokenSubject.asObservable();

    this.getToken().then((token: string | null) => {
      this.#tokenSubject.next(token);
    });
  }

  /**
   * Get the observable of the token
   *
   * @returns An observable of the token
   */
  public get tokenObserver(): Observable<string | null> {
    return this.#tokenObserver;
  }

  /**
   * Get the token from storage
   *
   * @returns A string if the token exists else a null
   */
  public async getToken(): Promise<string | null> {
    return await this.storage.get(TOKEN);
  }

  /**
   * Set the token in storage
   *
   * @param newToken The new string token
   */
  public async setToken(newToken: string): Promise<void> {
    await this.storage.set(TOKEN, newToken);

    this.#tokenSubject.next(newToken);
  }

  /**
   * Gets the refresher token from storage
   *
   * @returns A string or null from storage
   */
  public async getRefresherToken(): Promise<string | null> {
    return await this.storage.get(REFRESH);
  }

  /**
   * Sets the refresher token
   *
   * @param newRefreshToken The new string value
   */
  public async setRefresherToken(newRefreshToken: string): Promise<void> {
    await this.storage.set(REFRESH, newRefreshToken);
  }

  /**
   * Gets the user id from storage
   *
   * @returns string or null value of user id
   */
  public async getUserId(): Promise<string | null> {
    return await this.storage.get(USER);
  }

  /**
   * Sets the user id in storage
   *
   * @param userId The user id of the currently logged in user
   */
  public async setUserId(userId: string): Promise<void> {
    await this.storage.set(USER, userId);
  }

  /**
   * Check if the current user is logged in
   *
   * @returns true or false if the user is logged in
   */
  public async isLoggedIn(): Promise<boolean> {
    const value = await this.getToken();

    if (value == null) {
      return false;
    }

    if (value && !this.jwtHelper.isTokenExpired(value)) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Log out user and clear storage
   */
  public logout(): void {
    this.storage.clear().then(async () => {
      this.#tokenSubject.next(null);
      await this.router.navigateByUrl('/login');
    });
  }
}
